import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import frLocale from '@fullcalendar/core/locales/fr';
const $ = require('jquery');

document.addEventListener('DOMContentLoaded', function() {
    var calendarEl = document.getElementById('user_calendar');

    var calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin],
        events: getDomEventData(),
        locales: [ frLocale ],
        locale: 'fr'
    });
    console.log("init event")
    calendar.render();
});

function getDomEventData() {
    var events = [];
    $('.custom-event__list .custom-event__item').each(function() {
        events.push({
            title: 1,
            start: $(this).attr('datetime').substr(0,10),
        })
    })
    return agregateEvents(events);
}
function agregateEvents(events){
    let dtes      = []
    let dtesCount = []
    events.map((item,index)=>{
        if(dtes.includes(item.start)){
            dtesCount[dtes.indexOf(item.start)] = dtesCount[dtes.indexOf(item.start)]+1
        }else{
            dtes      = [...dtes, item.start]
            dtesCount = [...dtesCount, 1]
        }
    })

    let rs = []
    for (let index = 0; index < dtes.length; index++) {
        rs = [
            ...rs,
            { title: dtesCount[index], start: dtes[index]}
        ]
    }
    console.log(rs)
    return rs;
}