delete_content()
var indexMetier = $('.metier').length + 1;
$(function () {
    $('#btn-metier-add').click(function (e) {
        e.preventDefault();
        var list = $('.metier');
        var newWidget = $('.metiers').attr('data-prototype');
        newWidget = '<div class="metier_content">'
            + newWidget +
            "<a class=' btn btn-danger fa fa-remove delete_content mb-3' href='#'>" + "Supprimer" + "</a>" +
            '</div>';
        newWidget = newWidget.replace(/__name__/g, indexMetier);
        indexMetier++;
        list.append(newWidget);
        delete_content()
    });

    //$('.col-form-label').addClass('d-none');
    $('.col-form-label').remove();
});


function removeLegend() {
   $('.col-form-label').remove(); 
}
function delete_content() {
    $('.delete_content').click(function (e) {
        e.preventDefault();
        $(this).parent().remove();
    })
}