delete_content()

var index = $('.inviteFriendsOnUwandzani').length + 1;
$(function () {
    $('#btn-inviteFriendsOnUwandzani-add').click(function (e) {
        e.preventDefault();
        var list = $('.inviteFriendsOnUwandzani');
        var newWidget = $('.inviteFriendsOnUwandzanis').attr('data-prototype');
        newWidget = '<div class="inviteFriendsOnUwandzani_content">'
            + newWidget +
            "<a class='btn btn-danger fa fa-remove delete_content mb-3' href='#'>" + "Supprimer" + "</a>" +
            '</div>';
        newWidget = newWidget.replace(/__name__/g, index);
        index++;
        list.append(newWidget);
        delete_content()
        $('.col-form-label').remove();

        $('.js-example-basic-single').select2({
            language: 'fr',
            theme: 'bootstrap',
        });
        $('fieldset').hide()
    });
});

function removeLegend() {
    $('.col-form-label').remove();
}
function delete_content() {
    $('.delete_content').click(function (e) {
        e.preventDefault();
        $(this).parent().remove();
    })
}