$(document).ready(function () {
    setTimeout(function () {
        $('#spinner').addClass('d-none');
        $('#resulat').removeClass('d-none');
    }, 4000);

});


// Cette fonction affiche et gère la réalisation d'exercice
$(function () {

    // number of question:
    var numberQuestion = $('.questions').length;
    var nb_valide = 0;
    var nb_invalide = 0;
    var percent_valide = 0;
    var percent_invalide = 0;
    var noteRelative = 0;
    var noteReponseValides = 0
    var noteExercice = noteReponseValides;//noteRelative/numberQuestion;
    var nbCoche = 0;
    var ndbValideBdd = $('#nbReponseValideBdd').data('nbReponseValidesBdd');
    var noteValide = 0;
    var idValides = 0;
    var tableauIdValide = [];
    var tableauIdInvalide = [];
    var caseCoches = 0;
    var idCaseCoche = 0;

    $('#idCaseCoche').val(idCaseCoche);


    /*
                for (var i = 0; i < tableauCaseCoches.length; i++) {
                    
                    caseCoches += '-'+ $(tableauCaseCoches[i]).attr('value');
                    i++;
                }
    */
    //ici l'action affichage du second view
    $(document).on('click', '#show-second-view', function () {
        /*
         * Je sélectionne l'élément qui l'id show-second-view le premier ancêtre qui a la classe questions
         * Et depuis ce premier élément ancêtre qui a la classe "questions"
         * Trouver tous les éléments qui la classe seconde-view
         * Et cet élément qui a la classe second-view
         * Lui enlever la classe d-none
         */
        $(this).closest('.questions').find('.second-view').removeClass('d-none');

        /*
* Je sélectionne l'élément qui l'id show-second-view le premier ancêtre qui a la classe questions
* Et depuis ce premier élément ancêtre qui a la classe "questions"
* Trouver tous les éléments qui la classe first-view
* Et cet élément qui a la classe first-view
* Lui ajouter la classe d-none
*/
        $(this).closest('.questions').find('.first-view').addClass('d-none');
    });

    /*
    // Je prend en compte les réponses donné
    $('input[type=checkbox]').click(function(){

            //console.log("aaa")


            if($(this).hasClass('valide'))  {

                if (this.checked == true) {

                    tableauIdValide [nb_valide] = $(this).attr('value');
                    
                    noteValide += $(this).data('note-reponse');
                    $('#noteValides').val(noteValide);
                    idValides += '-'+$(this).attr('value');
                    $('#idValides').val(idValides);
                    nb_valide ++;                            
                } else if ($(this.checked != true)) {

                    tableauIdValide [nb_valide] = $(this).attr('value');
                    nb_valide --;
                }
            }else if ($(this).hasClass('invalide')) {
                if (this.checked == true) {
                    nb_invalide ++;
                    noteInvalide += $(this).data('note-reponse');
                    idInvalides += $(this).value();
                    $('#noteInvalides').val(noteValide);     
                } else {
                    nb_invalide --;
                }
            }
    
        if (this.checked == true) {
            var checkeds = $('#exercice input[type=checkbox]:checked.valide');
        }
    })
    */

    /*
    $('#question_precedent').click(function(){
        var currentQuestion = $('.questions.currentQuestion')
        var index = currentQuestion.data('index');
        if (index > 1) {
            currentQuestion.removeClass('currentQuestion').addClass('d-none');
            index--;
            $("[data-index |= '"+index+"']").removeClass('d-none').addClass('currentQuestion');
        } else {

        }
        $('#question_suivante').html('Question<br>Suivante');
    });
    */

    $(".btnAfficherBonneReponse").click(function () {

        /*
         * Au clic sur l'élément qui a la classe btnAfficherBonneReponse
         * Créer une variable nommé currentQuestion dans laquelle je stock l'élément qui a les classes questions et currentQuestion
         * Créer une variable index dans laquelle je stocke la valeur de l'attribue data  nommé index
         */
        var currentQuestion = $('.questions.currentQuestion')
        var index = currentQuestion.data('index');
        //console.log(index)

        /*
         * A l'élément qui l'id show-second-view selectionner le premier ancêtre qui a la classe questions
         * Et depuis ce premier élément ancêtre qui a la classe "questions"
         * Trouver tous les éléments qui la classe second-view
         * Et cet élément qui a la classe second-view
         * Lui ajouter la classe d-none
         */
        $(this).closest('.questions').find('.second-view').addClass('d-none');

        /*
         * A l'élément qui l'attribue data-reponse qui est égale à la variable index
         * Lui enlever la classe d-none
         */
        $("[data-reponse |= '" + index + "']").removeClass('d-none');
    })

    // Je montre la div exercice et la div visio si j'ai cliqué sur un groupe
    $('.groupe').click(function () {
        var dialecte = $(this).data('dialecte');
        $('#visio_groupe').removeClass('d-none');
        $('#choix_groupe').addClass('d-none');
        //$('#visio_groupe').slideDown("slow");
        $('#visio').slideDown("slow");
        $('#nomDialecte').text(dialecte);
        $('#modalNomDialecte').modal('show')
    });


    // Ici j'affiche la bonne réponse
    // $(document).on('click', '#exercice .btn-reponse', function()
    // {
    //     console.log("aaaaaa")
    //     var checked    = $('#exercice input[type=checkbox]:checked');
    //     var checked_id = '';

    //     for (var i = 0; i < checked.length; i++) checked_id += '-' + $(checked[i]).attr('value');

    //     checked_id = ( checked_id.replace('-', '').trim() === '' ) ? '0' : checked_id.replace('-', '');
    //     var url    = $(this).attr('url').replace('mesReponses', checked_id );

    //     $('#spinner-globale').show();
    //     $.get(url, function (data)
    //     {
    //         var donne = $.parseJSON( data );
    //         for (var i = 0; i < donne.reponses.length; i++)
    //         {
    //             var d = donne.reponses[i];
    //             //$('#exercice .bonne-reponses ul').appeend('<li>' + d.content + '</li>');
    //             $('.explications .panel-body ul').append('<div class="list-group-item"><h4 class="list-group-item-heading">' + d.content + '</h4><p class="list-group-item-text">' + d.explication + '</p></div>')
    //         }
    //         $('#exercice .btn-reponse').hide();
    //         $("#exercice .bonne-reponses").removeClass('d-none');
    //         $('#spinner-globale').hide();
    //     })
    // })


    // Ici j'affiche l'explication
    $(document).on('click', '#exercice .btn-invitationExplication', function () {
        // $('.bonne-reponses').hide();
        // $('.explications').slideDown('slow');
        // $('.questions').slideUp('slow');
        $(this).closest('.questions').find('.invitationExplications').removeClass('d-none');
        //console.log($(this).closest('.questions').find('.explications'))
        //$(this).closest('.questions').find('.explications').slideDown('slow');
        //$(this).closest('.questions').slideUp('slow');
        $(this).closest('.questions').find('.bonne-reponses').addClass('d-none');
    })


    // Ici j'affiche l'explication de la réponsé
    $(document).on('click', '#exercice .btn-bonne-explication', function () {
        $(this).closest('.questions').find('.invitationExplications').addClass('d-none');
        $(this).closest('.questions').find('.affichage-explications').removeClass('d-none');
        $('.explications .panel-body').slideDown();
        $(this).addClass('d-none');

        $('#question_suivante').removeClass('d-none');
    });

    $('#question_suivante').click(function () {

        $(this).addClass('d-none');
        var currentQuestion = $('.questions.currentQuestion')
        var index = currentQuestion.data('index');


        if (index != numberQuestion && index < numberQuestion) {


            $('#question_suivante').click(function () {

                //$(this).html('Voir le <br> resultat');
                //$(this).attr('id', 'afficher_note');
                /*
                $('#exercice .btn-bonne-explication').click(function(){

                    //$('#afficher_note').removeClass('d-none')
                });
                */

                /*
                $(this).click(function(){
                    for (var i = 0; i < tableauIdValide.length; i++) {
                
                        idValides += '-'+tableauIdValide[i]
                    }

                    for (var j = 0; j < tableauIdInvalide.length; j++) {
                        idInvalides += '-'+tableauIdInvalide[j]
                    }
                })
                */

                $('#afficher_note').click(function () {


                    $('#exercice .affichage-explications').addClass('d-none');


                    url = $('#id-exercice').data('url');
                });
            });
        }


        if (numberQuestion >= index) {

            currentQuestion.removeClass('currentQuestion').addClass('d-none');
            index++;
            $("[data-index |= '" + index + "']").removeClass('d-none').addClass('currentQuestion');

            if (numberQuestion == index) {

                $('#question_suivante').click(function () {
                    $('#btnSubmitQcm').removeClass('d-none');
                })
            }
        }
    });
});