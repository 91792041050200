import $ from 'jquery';
export default class notification {

    constructor() {
        this.mercure_url = process.env.MERCURE_PUBLISH_URL;
        this.base_url = process.env.APP_BASE_URL;
        this.url = new URL(process.env.MERCURE_PUBLISH_URL);
        this.mp3 = process.env.APP_BASE_URL + "/mp3/notification.mp3";
        this.mp3Notification = process.env.APP_BASE_URL + "/mp3/alerte.mp3";
        this.audio = new Audio(this.mp3);
        this.audioNotification = new Audio(this.mp3Notification);
        this.userId = document.getElementById('id_user').value;
        this.elementContenu = document.getElementById("contenu_notification");
    }



    run() {
        this.showMessageNoRead()
        this.listenerMessaging("notification/messaging/" + this.userId);
        this.listenerConnection("notification/connection/" + this.userId);
        this.showNotificationNoRead()

        this.listenerNotifiationProfil("notification/profil");
    }

    eventRessource(topic) {
        this.url.searchParams.append('topic', topic)
        let event = new EventSource(this.url, { withCredentials: false });
        this.url = new URL(process.env.MERCURE_PUBLISH_URL);
        return event;

    }

    showNotification(contenu) {
        this.elementContenu.innerHTML = contenu
        this.audio.play();
        $('.toast').toast('show');
    }


    showMessageNoRead() {

        if(this.userId != 0){
            $.get(this.base_url + "/message_api/user/me/message/unread/" + this.userId, function (data) {

                try {
                    if ($('#notif-message')) {
                        $('#notif-message').text((data.unreadCount > 0) ? data.unreadCount : "")
                    }
    
                    if ($("#notif-message-mobile")) {
                        $("#notif-message-mobile").text((data.unreadCount > 0) ? data.unreadCount : "")
                    }
    
                    if ($("#notif-message-mobile-1")) {
    
                        if (data.unreadCount > 0) {
    
                            $("#notif-message-mobile-1").css({ "background-color": "red", "color": "white", "font-size": "1rem", "display": "block" });
                        }
    
                        $("#notif-message-mobile-1").text((data.unreadCount > 0) ? data.unreadCount : "")
                    }
    
    
                } catch (error) {
                    console.error(error);
                }
    
            })

        }
      
    }

    showNotificationNoRead() {
        if(this.userId != 0){
            $.get(this.base_url + "/message_api/user/me/activite/unread/" + this.userId, function (data) {

                try {
                    if ($('#notif-activite')) {
                        $('#notif-activite').text((data.unreadCount > 0) ? data.unreadCount : "");
                    }
    
                    /*if($("#notif-message-mobile-1")) {
                        if (data.unreadCount > 0) {
                
                            $("#notif-message-mobile-1").css({"background-color": "red", "color": "white", "font-size": "1rem", "display":"block"});
                        }
                        
                        $("#notif-message-mobile-1").text((data.unreadCount > 0) ? data.unreadCount : "" )
                   }*/
    
    
                    if ($("#notif-activite-mobile")) {
    
                        if (data.unreadCount > 0) {
    
                            $("#notif-activite-mobile").css({ "background-color": "red", "color": "white", "font-size": "1rem" });
                        }
    
                        $("#notif-activite-mobile").text((data.unreadCount > 0) ? data.unreadCount : "");
                    }
    
                    if ($("#notif-visite")) {
                        $("#notif-visite").text((data.visiteProfilCount > 0) ? data.visiteProfilCount : "");
                        $("#notif-favoris").text((data.favorisProfilCount > 0) ? data.favorisProfilCount : "");
                        $("#notif-flash").text((data.flashProfilCount > 0) ? data.flashProfilCount : "");
                        $("#notif-amis").text((data.amisProfilCount > 0) ? data.amisProfilCount : "");
                        $("#notif-autorisation").text((data.autorisationProfilCount > 0) ? data.autorisationProfilCount : "");
                    }
    
                    if ($('#view-apparence')) {
                        $("#view-apparence").text((data.viewApparenceProfilCount > 0) ? data.viewApparenceProfilCount : "");
                        $("#view-photo").text((data.viewPhotoProfilCount > 0) ? data.viewPhotoProfilCount : "");
                        $("#view-social").text((data.viewSocialProfilCount > 0) ? data.viewSocialProfilCount : "");
                        $("#view-professionelle").text((data.viewSocialProfilCount > 0) ? data.viewSocialProfilCount : "");
                        $("#view-culturelles").text((data.viewCulturelleProfilCount > 0) ? data.viewCulturelleProfilCount : "");
                        $("#view-formation").text((data.viewFormationProfilCount > 0) ? data.viewFormationProfilCount : "");
                        $("#view-langue").text((data.viewLangueProfilCount > 0) ? data.viewLangueProfilCount : "");
                        $("#view-demande").text((data.viewDemandeProfilCount > 0) ? data.viewDemandeProfilCount : "");
                        $("#view-proposition").text((data.viewPropositionProfilCount > 0) ? data.viewPropositionProfilCount : "");
                        $("#view-mode").text((data.viewModeProfilCount > 0) ? data.viewModeProfilCount : "");
                        $("#view-objectif").text((data.viewObjectifProfilCount > 0) ? data.viewObjectifProfilCount : "");
                    }
    
    
                } catch (error) {
                    console.error(error);
                }
    
    
            })
        }
       

    }

    listenerMessaging(topic) {
        let that = this
        this.eventRessource(topic).onmessage = (evt) => {

            let data = JSON.parse(evt.data)
            let inputDestination = document.getElementById('id_participant')
            let idDestionation = (inputDestination == null) ? 0 : inputDestination.value
            if (idDestionation === 0 || data.owner != idDestionation) {

                var inputNotif = document.getElementById('notif-message')
                var inputNotifMobile = document.getElementById('notif-message-mobile')
                var inputNotifMobile1 = document.getElementById('notif-message-mobile-1')
                that.showNotification("un nouveau message de <br> <b>" + data.firstname + "  " + data.lastname + "</b>")
                if (inputNotif != null) {
                    inputNotif.textContent = parseInt(inputNotif.textContent == "" ? 0 : inputNotif.textContent) + 1
                }

                if (inputNotifMobile != null) {
                    inputNotifMobile.textContent = parseInt(inputNotifMobile.textContent == "" ? 0 : inputNotifMobile.textContent) + 1

                    inputNotifMobile.style.display = 'block'
                }

                if (inputNotifMobile1 != null) {
                    inputNotifMobile1.textContent = parseInt(inputNotifMobile1.textContent == "" ? 0 : inputNotifMobile1.textContent) + 1
                    //  inputNotifMobile1.style.display = 'block !important'
                }

            } else {
                this.audio.play();
                $.post(this.base_url + "/message_api/user/me/conversation/" + data.conversation, function () {
                    console.log(data)
                })
            }

        }

    }


    listenerConnection(topic) {
        let that = this
        this.eventRessource(topic).onmessage = (evt) => {
            let data = JSON.parse(evt.data)
            $('#photo-profil-notification').attr('src', data.photo)
            $('#photo-profil-pseudo').text(data.pseudo)
            that.elementContenu.innerHTML = "viens se connecter"
            that.audioNotification.play();
            $('.toast').toast('show');

        }
    }




    listenerNotifiationProfil(topic) {
        let that = this
        this.eventRessource(topic).onmessage = (evt) => {
            if ("" !== evt.data) {
                let data = JSON.parse(evt.data)

                if (!(isNaN(this.userId)) && parseInt(this.userId) == parseInt(data.user_id)) {
                    console.log(data)
                    that.showNotification(data.message)
                    let inputActivite = document.getElementById('notif-activite')
                    let inputActiviteMobile = document.getElementById('notif-activite-mobile')
                    let inputElement = document.getElementById(data.element_id)
                    let inputviewAutorisation = null

                    if (data.element_id.split('_').length == 2) {
                        inputElement = document.getElementById(data.element_id.split('_')[0])
                        inputviewAutorisation = document.getElementById(data.element_id.split('_')[1])
                    }



                    if (inputActivite) {
                        inputActivite.textContent = parseInt(inputActivite.textContent == "" ? 0 : inputActivite.textContent) + 1

                    }

                    if (inputActiviteMobile) {
                        inputActiviteMobile.textContent = parseInt(inputActiviteMobile.textContent == "" ? 0 : inputActiviteMobile.textContent) + 1

                    }



                    if (inputElement) {
                        inputElement.textContent = parseInt(inputElement.textContent == "" ? 0 : inputElement.textContent) + 1

                    }

                    if (inputviewAutorisation) {
                        inputviewAutorisation.textContent = parseInt(inputviewAutorisation.textContent == "" ? 0 : inputviewAutorisation.textContent) + 1
                    }


                }
            }

        }

    }









}