delete_content()


var index = $('.groupeLinguistiqueUser').length + 1;
$(function () {
	$('#btn-groupeLinguistiqueUser-add').click(function(e){
		e.preventDefault();
		var list = $('.groupeLinguistiqueUser');
		var newWidget = $('.groupeLinguistiqueUsers').attr('data-prototype')
		newWidget = '<div class="row groupeLinguistiqueUser_content">'
		+newWidget+
		"<a class='fa fa-remove delete_content' href='#'>"+"Supprimer ce linguiste"+"</a>"+
		'</div>';
		newWidget = newWidget.replace(/__name__/g, index);
		list.append(newWidget);
		delete_content();
	});
});

function delete_content() {
	$('.delete_content').click(function(e) {
		e.preventDefault();
		$(this).parent().remove();
	})
}