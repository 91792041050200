delete_content()
var index = $('.video').length + 1;
$(function () {
    $('#btn-video-add').click(function (e) {
        e.preventDefault();
        var list = $('.video');
        var newWidget = $('.videos').attr('data-prototype');
        newWidget = '<div class="row video_content">'
            +newWidget+
            "<a class='fa fa-remove delete_content' href='#'>" + "Supprimer" +"</a>"+
            '</div>';
        newWidget = newWidget.replace(/__name__/g, index);
        list.append(newWidget);
        delete_content()
    });
});

function delete_content() {
    $('.delete_content').click(function (e) {
        e.preventDefault();
        $(this).parent().remove();
    })
}
