/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';
import './styles/select2.min.css';
import './styles/select2-bootstrap.css';
//import 'owl.carousel/dist/assets/owl.carousel.css';


// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
//const $ = require('jquery');

import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui';
import 'bootstrap';
//import './js/fontawesome-free-6.1.1/js/all';

// start the Stimulus application
import './bundles.js';

//import './js/jquery-ui.js';
//require('@fortawesome/fontawesome-free/css/all.min.css');
//require('@fortawesome/fontawesome-free/js/all.js');

//import './js/languesParles.js';
//import 'owl.carousel';
import './js/stepper.js';
import './js/owl.carousel.js';
import './js/owl.carousel.min.js';
//import 'popper.js';
import 'select2';
import './js/select2.min.js';
import './js/inscription.js';
import './js/messages';
import './js/jquery.fancybox.min.js';
//import './js/ajax_profil.js';
import './js/exercice.js';
import './js/linguiste.js';
import './js/recherche_langue.js';
import './js/main.js';
import './js/modalDemandeFruit.js';

import './js/notifications.js';
import './js/picker.min.js';
import './js/profilFormation.js';
import './js/response.js';
import './js/scripts.js';
import './js/ajust.js';
//import './js/selectize.min.js';
import './js/tokeninput.js';
import './js/video.js';
import './js/account.js';


//import './js/video_visionner.js';
//import './js/languesParles.js';
import './js/profil/metiers.js';
import './js/inviteFriendsOnUwandzani.js';
import './js/Availability/index.js';
import './js/user_calendar.js';


//import './mercure.js';

//import './js/logged';
import './js/nouislider';
import './js/typeahead.bundle.js';

//import './main.js';

//import './js/boot'
import 'popper.js';
import './js/fr.min.js';

import './js/progress.js';


//$('.js-example-basic-single').select2();

// Side bar

/********** Sile Bar *****/

$(document).ready(function(){
  $("#menu-toggle").click(function(e){
    e.preventDefault();
    $("#wrapper").toggleClass("menuDisplayed");
  });
});


$(document).ready(function(){
  $("#menu-profil").click(function(e){
    e.preventDefault();
    $("#wrapper").toggleClass("menuDisplayed");
  });
});

$(document).ready(function(){
  $("#menu-community").click(function(e){
    e.preventDefault();
    $("#wrapper").toggleClass("menuDisplayed");
  });
});

$(document).ready(function(){
  $("#menu-formation").click(function(e){
    e.preventDefault();
    $("#wrapper").toggleClass("menuDisplayed");
  });
});

// import './js/nav_bar';