
/*==============================================================
   Chat 
==============================================================*/
$(document).ready(function(){
    const smallDevice = window.matchMedia("(max-width: 767px)");
    const largeScreen = window.matchMedia("(max-width: 1199px)");
    smallDevice.addEventListener("change", handleDeviceChange);
    largeScreen.addEventListener("change", handleLargeScreenChange);
    $chat = $('.chat');
    $profile = $('.user-profile');
    // Run it initially
    handleDeviceChange(smallDevice);
    handleLargeScreenChange(largeScreen);

    function handleDeviceChange(e) {
    if (e.matches) chatMobile();
    else chatDesktop();
    }

    function handleLargeScreenChange(e) {
        if (e.matches) profileToogleOnLarge();
        else profileExtraLarge();
    }



    $('.messaging-member').on('click',function(){

        $chat.fadeIn();
        $chat.addClass('chat--show');
    });

    $('.chat__previous').click(function(){
        $chat.removeClass('chat--show');
    });


    function chatMobile(){
        $chat.addClass('chat--mobile');
    }

    function chatDesktop(){
        $chat.removeClass('chat--mobile');
    }


    $('.chat__menu').click(function(){
        $profile.fadeIn();
        $profile.addClass('user-profile--show');
    });

    $('.user-profile__close').click(function(){
        $profile.removeClass('user-profile--show');
    });


    function profileToogleOnLarge(){
        $profile.addClass('user-profile--large');
    }

    function profileExtraLarge(){
        $profile.removeClass('user-profile--large');
}
})
