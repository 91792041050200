
import "./../../styles/availability.css"
var $itemModel
var change = 0
$(document).ready(function () {
    $itemModel = $('.availability-group').find('.item:last')
    initCollectionType()
})

/*** collection type handler */
function initCollectionType() {
    $(document).on('click', '.remove_time_slot', function (e) {
        e.preventDefault();
        $(this).closest('.timeSlots-proto-wrapper')
            .fadeOut()
            .remove();
    });
    $(document).on('click', '.add_time_slot', function (e) {
        e.preventDefault();
        var $wrapper = $(this).parents('.modal-body').find('.timeSlots-data-proto-wrapper');
        var prototype = $wrapper.data('prototype')
        var index = $wrapper.data('index')
        var newForm = prototype.replace(/__name__/g, index);
        $wrapper.data('index', index + 1)
        $(this).parents('.modal-body').find('.item-target').before(newForm)
    });

}