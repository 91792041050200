//  Cette méthode affiche le modal de demande des fruit
$('.demanderFruit, #demanderFruitAbonnement, #demanderMessage').click(function(e){
    

    var messageModal = $(this).data('message-modal');

    //e.prevantDefault();
    $('#text-ajout-modal-fruit').text(messageModal)
    $('#demandeFruitModalCenter').modal("show");
    
/*
    var url = $(this).data('url');
    var typeFruit = $(this).data('typeFruit');
    var idFruit = $(this).data('idFruit');
    var typeDemande = $(this).data('typeDemande');
            
    $.ajax({
        url: url,
        type: 'POST',
        data: {'typeFruit': typeFruit, 'idFruit': idFruit, 'typeDemande': typeDemande},
        dataType: 'json',

        success: function(value){

            $('#demandeFruitModalCenter').modal("show")

            typeFruit = value.typeFruit;
            idFrui = value.idFruit;

            $('#envoyerDemandeInformationFruit').click(function(){

                url = this.data('url');

                $.ajax({
                    url: url,
                    type: 'POST',
                    data: {'typeFruit': typeFruit, 'idFruit': idFruit},
                    dataType: 'json',

                    success: function(){
                                // Ici on va lancer une alerte
                    } 
                }); 
            });                   
        }
    })
*/   
});

$('#flashConfirmationDemandeFruit').slideUp();

$('#envoyerDemandeInformationFruit').click(function(){


    $('#flashConfirmationDemandeFruit').removeClass('d-none');
    $('#flashConfirmationDemandeFruit').slideDown();

    setTimeout(function()
        {
            $('#demandeFruitModalCenter').modal('hide');
        }, 4000);

    
    
    setTimeout(function() {
        $('#flashConfirmationDemandeFruit').slideUp();
            setTimeout(function() {
                $('#flashConfirmationDemandeFruit').addClass('d-none');
                $('#flashConfirmationDemandeFruit').slideDown();
            }, 1000);
    }, 3000);
});

