delete_content()
var index = $('.invalid_reponse').length + 1;
var index_valide = $('.valid_reponse').length + 1;
$(function () {
    $('#btn-response-add').click(function (e) {
        e.preventDefault();
        var list = $('.reponse');
        var newWidget = $('.reponses').attr('data-prototype');
        newWidget = '<div class="row reponse_content">'
            +'<h4>'+'Réponse N°' + index +'</h4>'
            +newWidget+
            "<a class='fa fa-remove delete_content' href='#'>" + "Supprimer" +"</a>"+
            '</div>';
        newWidget = newWidget.replace(/__name__/g, index);
        index++;
        list.append(newWidget);
        delete_content()
    });

    $('#btn-response-valide-add').click(function (e) {
        e.preventDefault();
        var list = $('.valide');
        var newWidget = $('.reponses').attr('data-prototype-valide');
        newWidget = '<div class="row reponse_content">'
            +'<h4>'+'Réponse valide N°' + index_valide +'</h4>'
            +newWidget+
            "<a class='fa fa-remove delete_content_valide' href='#'>" + "Supprimer" +"</a>"+
            '</div>';
        newWidget = newWidget.replace(/__name__/g, index_valide);
        index_valide++;
        list.append(newWidget);
        delete_content()
    })
});

function delete_content() {
    $('.delete_content_valide').click(function (e) {
        e.preventDefault();
        index_valide --;
        $(this).parent().remove();
    })

    $('.delete_content').click(function (e) {
        e.preventDefault();
        index --;
        $(this).parent().remove();
    })
}
