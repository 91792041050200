$(document).ready(function(){

     $selectVoudraitAutresEnfants = $('#situation_sociale_profil_voudraitAutresEnfants');
    let $voudrai = $selectVoudraitAutresEnfants.val();
    $('.nav-link').click(function(e){
        $('.js-example-basic-single').select2({
            language: 'fr',
            theme: 'bootstrap',
        });
        e.preventDefault()
        $('html, body').animate({ 
             scrollTop: '0px' 
         }, 
         0); 


    })

    if($voudrai === 'Oui') {
        $('#situation_sociale_profil_combienAutresEnfants').closest('.form-group').removeClass('d-none');
    }else{
        $('#situation_sociale_profil_combienAutresEnfants').closest('.form-group').addClass('d-none');
    }

    $selectVoudraitAutresEnfants.change(function(){
        let $voudrai = $(this).val();
        if($voudrai === 'Oui') {
            $('#situation_sociale_profil_combienAutresEnfants').closest('.form-group').removeClass('d-none');
        }else{
            $('#situation_sociale_profil_combienAutresEnfants').closest('.form-group').addClass('d-none');
        }
    })



    function InfoProfilDetail() {
       if(document.getElementById('detail-profil') != undefined) {
        


            var pill_nav = document.getElementById('nav-pills-desktop')


            var sticky = document.getElementById('detail-profil').offsetTop;

        
    
                if (window.pageYOffset > sticky) {
                    let largeur  = pill_nav.offsetWidth
                    pill_nav.style.width = largeur + 'px'
                    //pill_nav.classList.add("fix-top");
                    pill_nav.style.height = "auto"
                    document.getElementById('detail-profil').style.left = largeur + 'px'
                } else {
                   // pill_nav.classList.remove("fix-top");
                  //  pill_nav.style.width = 'unset'
                    pill_nav.style.height = "100%"
                    document.getElementById('detail-profil').style.left = "unset"
                }
            } 
    }

    function myFunction() {
            if(document.getElementById('nav-pills-desktop') != undefined) {
        


                var pill_nav = document.getElementById('nav-pills-desktop')


                var sticky = pill_nav.offsetTop;

            
        
                    if (window.pageYOffset > sticky) {
                        let largeur  = pill_nav.offsetWidth
                        pill_nav.style.width = largeur + 'px'
                        pill_nav.classList.add("fix-top");
                        pill_nav.style.height = "auto"
                    } else {
                        pill_nav.classList.remove("fix-top");
                        pill_nav.style.width = 'unset'
                        pill_nav.style.height = "100%"
                    }
                } 
    }    
    
    function myFunction_menu() {

        if(document.getElementById('open-menu-edit-profil-mobile') != undefined) {

            var pill_nav = document.getElementById('open-menu-edit-profil-mobile')


            var sticky = pill_nav.offsetTop;

       
            if(document.getElementById('myNav').offsetWidth == 0 ) {
                    if (window.pageYOffset > sticky) {
                        pill_nav.classList.add("fix-top");
                        } else {
                            pill_nav.classList.remove("fix-top");
                        }
            }
        
            } 
        }

    function resposiveNavBar() {
        if(document.getElementById('bloc_profil') !== null) {
            let tailleNAV=  (document.getElementById('bloc_profil').offsetWidth * 25) /100 + 'px'
            document.getElementById('nav-pills-desktop').style.width = tailleNAV
            let largeurWindows = $(window).width()
            if(largeurWindows <= 991) {
                $('#nav-pills-desktop').hide()
                $('#detail-profil').removeClass('col-sm-9').addClass('col-sm-12')
                document.getElementById('detail-profil').style.left = "unset"
            }else{
                $('#nav-pills-desktop').show()
                      
                $('#detail-profil').removeClass('col-sm-12').addClass('col-sm-9')
      
                if(!$('#nav-pills-desktop').hasClass('form-edit')) {
          
                    $('#detail-profil').removeClass('col-sm-12').addClass('col-sm-9')
                    if($('#nav-pills-desktop').hasClass('fix-top')) {
                        document.getElementById('detail-profil').style.left = tailleNAV
                    }
                }
         
          
            }
        }

    }


    window.onscroll = function() {
        myFunction()
        myFunction_menu()
        InfoProfilDetail()
    };

    
    $(window).resize(function(){
       resposiveNavBar()
       InfoProfilDetail()
    })

        

    $('#v-pills-langue-tab').click(function(){
        $('#tab_initial').click()
    })

    $('#link-identite').click(function(){
        $('.nav-detail-profil .nav-link').slideToggle('fast')
        if($('.icon-nav').hasClass('icon-chevron-right')){
            $('.icon-nav').addClass('icon-chevron-down')
            $('.icon-nav').removeClass('icon-chevron-right')
        }else{
            $('.icon-nav').addClass('icon-chevron-right')
            $('.icon-nav').removeClass('icon-chevron-down')
        }
    })

    $('.nav-detail-profil .nav-link').click(function(){
        window.location.href ="#identite"
    })

    resposiveNavBar()
    InfoProfilDetail()

    $('#nav-pills-desktop .nav-link').click(function(){
        $('#nav-pills-desktop .list-group-item').removeClass('nav_active')
        $('#link-identite').addClass('nav_active')
    })

    $('#nav-pills-desktop .list-group-item').click(function(){
        $('#nav-pills-desktop .list-group-item').removeClass('nav_active')
        $(this).addClass('nav_active')
    })


    $('.tree-mot-add').on('click' , function(){
        let nombre =  $(this).closest('.tree-mot-content').find('.tree-mot').length;
        if(nombre == 3) {
            $(this).addClass('d-none')
        }
    });

    $(document).on('click', '.btn-tree-mot' , function(e){
       if($(this).hasClass('btn-remove-threeWordsGovernLife')) {
        $('#btn-threeWordsGovernLife-add').removeClass('d-none')
       }
       if($(this).hasClass('btn-remove-personalBenefit')) {
        $('#btn-personalBenefit-add').removeClass('d-none')
        }
        if($(this).hasClass('btn-remove-socialbenefit')) {
            $('#btn-socialBenefit-add').removeClass('d-none')
        }
    })


    $(document).on('change', '.categoryPractice,.activityPractice', function () {
        let categoryField = $(this);
        let form = categoryField.closest('.profilActivityPractice');
        let target =  '#' + categoryField.attr('id').replace('_activityPractice','').replace('_categoryPractice', '');
        let data = {};
        data[categoryField.attr('name')] = categoryField.val();
        $.post(form.attr('action'), data).then(function (reponse) {
            let $input = $(reponse).find(target);
            $(target).replaceWith($input)
            // On récupère le nouveau <select>
            //let $input = $(data).find(target)
            // On remplace notre <select> actuel
           // $(target).replaceWith($input)
          })

      /*  let $field = $(this)
        let $regionField = $('#appbundle_medecin_region')
        let $form = $field.closest('form')
        let target = '#' + $field.attr('id').replace('departement', 'ville').replace('region', 'departement')
        // Les données à envoyer en Ajax
        let data = {}
        data[$regionField.attr('name')] = $regionField.val()
        data[$field.attr('name')] = $field.val()
        // On soumet les données
        $.post($form.attr('action'), data).then(function (data) {
          // On récupère le nouveau <select>
          let $input = $(data).find(target)
          // On remplace notre <select> actuel
          $(target).replaceWith($input)
        })*/
      })




});



