$("#continent-list").change(function () {
    alert('Le continent a changé')
    if ($(this).val() != 0) {
        $('#pays-list').empty()
        var continent = $(this).val()
        var url = '{{ path("get_pays_continent", {"id": "id_continent" }) }}'
        url = url.replace("id_continent", continent)
        $.ajax({
            url: url,
            success: function (data) {
                $array_ed = $.parseJSON(data)
                $("#pays-list").append($('<option>Choisir le pays</option>'));
                $.each($array_ed, function (key, value) {
                    $("#pays-list").append($('<option></option>').attr('value', value['id']).text(value['nom']));
                })
            }
        })
    }
});

$("#pays-list").change(function () {
    if ($(this).val() != 0) {
        $('#langue-list').empty()
        var pays = $(this).val()
        var url = "{{ path('get_pays_langues', {'id': 'id_pays' }) }}"
        url = url.replace("id_pays", pays)

        $('#spinner-globale').show();

        $.get(url, function (data) {
            var formations = $.parseJSON(data)
            console.log(formations);
            $('.langue').empty();
            for (var i = 0; i < formations.length; i++) {
                var formation = formations[i];
                var t = $('#langue-temp :first-child').first().clone();
                var id = 'formation-' + formation.id;

                t.attr('id', id);

                $('.langue').append(t)
                $('#' + id + ' .card-title').text(formation.nom);

                var imageName = $('#' + id + ' .card-img-top').attr('src');
                var lien = $('#' + id + ' .btn-success').attr('href').replace('formation_id', formation.id);

                $('#' + id + ' .card-img-top').attr('src', imageName + '/' + formation.imageName);
                $('#' + id + ' .btn-success').attr('href', lien);
            }
        });
    }
});