

$(document).ready(function(){
    $('[data-fancybox="gallery"]').fancybox({
        buttons: [
          "slideShow",
          "thumbs",
          "zoom",
          "fullScreen",
          "close"
        ],
        loop: false,
        protect: true
      });
      var old_position = 0;


      if (document.readyState === 'complete') {
        document.getElementById('loader1').style.display = 'none';
      } else {
        document.addEventListener('DOMContentLoaded', function() {
          document.getElementById('loader1').style.display = 'none';
        });
      }


      window.onscroll = function (e) {
        if(document.getElementById('mymenu1') != undefined) {
        
          var hMenu = document.getElementById('containt').offsetHeight 
          var hScreen = document.documentElement.clientHeight

          var pill_nav = document.getElementById('mymenu1')


          var sticky = pill_nav.offsetTop;

           
          if (window.pageYOffset > sticky) {
              let largeur  = pill_nav.offsetWidth
              pill_nav.style.width = largeur + 'px'
              pill_nav.classList.add("fix-top");
              pill_nav.style.height = "auto"
              pill_nav.style.zIndex = "1000"
              pill_nav.style.marginTop = '5px'
          } else {
              pill_nav.classList.remove("fix-top");
              pill_nav.style.width = 'unset'
              pill_nav.style.height = "100%"
              pill_nav.style.marginTop = '48px'
          }
            

          } 
      }

      $('.owl-carousel').owlCarousel({
        loop: false,
        margin: 20,
        nav: false,
        // items: 2,
        // rewind: true,
        dots: false,
        responsive: {
          0: {
            items: 1
          },
      
          600: {
            items: 2
          },
      
          1024: {
            items: 3
          },
      
          1366: {
            items: 4
          }
        }
      })



      // window.onscroll = function (e) {
      //   var vertical_position = 0;
      //   var h;
      //   var x
      //   if (pageYOffset)//usual
      //     vertical_position = pageYOffset;
      //   else if (document.documentElement.clientHeight)//ie
      //     vertical_position = document.documentElement.scrollTop;
      //   else if (document.body)//ie quirks
      //     vertical_position = document.body.scrollTop;  
      //     x=$("#mymenu1").offset();
      //     console.log(vertical_position);

      //   if(vertical_position > this.old_position){
      //       if (vertical_position == 0){
      //         h = document.getElementById('mymenu1').offsetHeight - document.documentElement.clientHeight;
      //         console.log(h);
      //         document.getElementById('mymenu1').style.top = -(h) + 'px';
      //         document.getElementById('mymenu1').style.backgroundColor = 'green'
      //         // document.getElementById('mymenu1').style.position = 'sticky'
      //       }else{
      //         document.getElementById('mymenu1').style.backgroundColor = 'red'
      //         document.getElementById('mymenu1').style.top = (h+vertical_position) + 'px';
      //         console.log('aa'+h);
      //         // 
      //       }
      //   }else if(vertical_position < this.old_position){
      //     console.log('miakatra');
      //     console.log(this.old_position+'miakatra'+vertical_position);
      //     this.old_position = vertical_position;
          
      //   }else{

      //   }

      //   // var limit = Math.max( document.body.scrollHeight, document.body.offsetHeight, 
      //   // document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
        

      // }

});


$( function() {
  $( "#sortable" ).sortable();
  $( "#sortable" ).disableSelection();
  $( "#sortable" ).on( "sortstop", function( event, ui ) {
  
    var rowCount = $('#Table >tbody >tr').length;

     var nouv_tri = [];

    mybody = document.getElementsByTagName("body")[0];
    mytable = mybody.getElementsByTagName("table")[0];
    mytablebody = mytable.getElementsByTagName("tbody")[0];
    //document.getElementById("Table").cells[0].innerHTML = 1;
    for (var index = 0; index < rowCount; index++) {
      myrow = mytablebody.getElementsByTagName("tr")[index];
      mycel = myrow.getElementsByTagName("td")[2];
      mycel.innerHTML = index+1;
      nouv_tri.push({id:index, langue: myrow.getElementsByTagName("td")[0].innerText, numero:index+1})
    }


  console.log(nouv_tri)
  });

} );




