import notification from "./notificationObject.js"
$(document).ready(function(){
    if(document.getElementById('id_user')) {
        let not = new notification()
        not.run()
    }

    $('#notifier').click(function(e) {
        e.preventDefault();
        var url = $('#notifier').data('url');
        var type_fruit = $('#notifier').data('type');

        alert(type_fruit);

                
        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'JSON',
            data: {"type_fruit": type_fruit},
            success: function(data){
                console.log(data)
            }
        })
    })
});