const slidePage = document.querySelector(".inscription-page__slide");
const nextBtnFirst = document.querySelector(".firstNext");
const nextBtnSec = document.querySelector(".next-1");
const nextBtnThird = document.querySelector(".next-2");
const nextBtnFourth = document.querySelector(".next-3");
const nextBtnFifth = document.querySelector(".next-4");
const nextBtnSixth = document.querySelector(".next-5");
const stepIndicator = document.querySelector(".steps-indicator__index");
const stepHint = document.querySelector(".steps-indicator__hint");
const stepBoxes = document.querySelectorAll(".steps-indicator__boxes li");
let current = 1;

/*
$(document).ready(function(){
    initTypeAheadInputTag();
    initNoUiSlider();
});
*/

/*
function initTypeAheadInputTag(){
    var languages = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('text'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: './assets/data/languages.json'
    });

    var hobbies = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('text'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: './assets/data/hobbies.json'
    });

    languages.initialize();
    hobbies.initialize();
      
    var $languageInput = $('.language-autocomplete');
    $languageInput.tagsinput({
        itemValue: 'value',
        itemText: 'text',
        tagClass: 'custom-taginput',
        typeaheadjs: {
            name: 'languages',
            displayKey: 'text',
            source: languages.ttAdapter()
        }
    });

    var $hobbiesInput = $('.hobbies-autocomplete');
    $hobbiesInput.tagsinput({
        itemValue: 'value',
        itemText: 'text',
        tagClass: 'custom-taginput',
        typeaheadjs: {
            name: 'languages',
            displayKey: 'text',
            source: hobbies.ttAdapter()
        }
    });
}
*/

// declare variable
var scrollTop = $(".scrollTop");

setTimeout(function () {
  //Click event to scroll to top
  $(scrollTop).click(function () {
    $("html, body").animate(
      {
        scrollTop: 0,
      },
      800
    );
    return false;
  }); // click() scroll top EMD
}, 700);

function initNoUiSlider() {
  var slider = document.getElementById("slider");

  noUiSlider.create(slider, {
    start: [18, 40],
    step: 1,
    tooltips: [
      {
        to: function (value) {
          return value.toString().slice(0, 2);
        },
        from: function (value) {
          return Number(value);
        },
      },
      {
        to: function (value) {
          return value.toString().slice(0, 2);
        },
        from: function (value) {
          return Number(value);
        },
      },
    ],
    connect: true,
    range: {
      min: [16],
      max: [99],
    },
  });
}

/*==============================================================
    Events
==============================================================*/

$(".retour1").click(function (event) {
  event.preventDefault();
  slidePage.style.marginLeft = "0%";
  $("#e2").removeClass("done");
  $(".steps-indicator__index").html("");
  $(".steps-indicator__index").html("Etape 1/7");
  $(".steps-indicator__hint").html("Bienvenue chez Uwandzani !");
  //stepBoxes[current].classList.add("done");
  //stepIndicator.innerHTML = "Etape 1/7";
  //stepHint.innerHTML = "";
  //current -= 1;
});

$(".firstNext").click(function (event) {
  steep_1(event);
});

$(".retour2").click(function (event) {
  event.preventDefault();
  slidePage.style.marginLeft = "-14.29%";
  $("#e3").removeClass("done");
  $(".steps-indicator__index").html("");
  $(".steps-indicator__index").html("Etape 2/7");
  //$('.steps-indicator__hint').html('Bienvenue chez Uwandzani !')
  //stepBoxes[current].classList.add("done");
  //stepIndicator.innerHTML = "Etape 1/7";
  //stepHint.innerHTML = "";
  //current -= 1;
});

/*
nextBtnFirst.addEventListener("click", function(event){
  event.preventDefault();
  slidePage.style.marginLeft = "-14.29%";
  stepBoxes[current].classList.add("done");
  stepIndicator.innerHTML = "Etape 2/7";
  stepHint.innerHTML = "";
  current += 1;
});
*/

$(".next-1").click(function (event) {
  steep_2(event);
});

$(".retour3").click(function (event) {
  event.preventDefault();
  slidePage.style.marginLeft = "-28.58%";
  $("#e4").removeClass("done");
  $(".steps-indicator__index").html("");
  $(".steps-indicator__index").html("Etape 3/7");
  //$('.steps-indicator__hint').html('Bienvenue chez Uwandzani !')
  //stepBoxes[current].classList.add("done");
  //stepIndicator.innerHTML = "Etape 1/7";
  //stepHint.innerHTML = "";
  //current -= 1;
});

/*
nextBtnSec.addEventListener("click", function(event){
  event.preventDefault();
  slidePage.style.marginLeft = "-28.58%";
  stepBoxes[current].classList.add("done");
  stepIndicator.innerHTML = "Etape 3/7";
  stepHint.innerHTML = "";
  current += 1;
});
*/

$(".next-2").click(function (event) {
  steep_3(event);
});
$("#sniper").hide();
$("#btn_ajout_langue_anonyme").click(function (event) {
  event.preventDefault();
  langues = document.querySelectorAll(".select2-selection__rendered");
  exist_selectionne = false;
  exist_nonSelectionne = false;
  langues.forEach((element) => {
    if (
      element.textContent != "Tapez ci-dessous le nom d'une langue" &&
      element.textContent != ""
    ) {
      exist_selectionne = true;
    } else {
      if (element.textContent != "Tapez ci-dessous le nom d'une langue") {
        exist_nonSelectionne = true;
      }
    }
  });
  if ((!exist_selectionne && exist_nonSelectionne) || langues.length == 0) {
    $("#erreur_steep2").text("Veuillez choisir une langue");
  } else {
    $("#sniper").show();
    $(this).hide();
    document.getElementById("btn_ajout_langue_anonyme").disabled = true;
    $("#form-registration").submit();
  }
});

$(".retour4").click(function (event) {
  event.preventDefault();
  slidePage.style.marginLeft = "-42.87%";
  $("#e5").removeClass("done");
  $(".steps-indicator__index").html("");
  $(".steps-indicator__index").html("Etape 4/7");
  $("#erreur_steep4").text("");
  //$('.steps-indicator__hint').html('Bienvenue chez Uwandzani !')
  //stepBoxes[current].classList.add("done");
  //stepIndicator.innerHTML = "Etape 1/7";
  //stepHint.innerHTML = "";
  //current -= 1;
});

/*
nextBtnThird.addEventListener("click", function(event){
  event.preventDefault();
  slidePage.style.marginLeft = "-42.87%";
  stepBoxes[current].classList.add("done");
  stepIndicator.innerHTML = "Etape 4/7";
  stepHint.innerHTML = "Plus que quelques étapes";
  current += 1;
});
*/

$(".next-3").click(function (event) {
  steep_4(event);
});

$(".retour5").click(function (event) {
  event.preventDefault();
  slidePage.style.marginLeft = "-57.16%";
  $("#e6").removeClass("done");
  $(".steps-indicator__index").html("");
  $(".steps-indicator__index").html("Etape 5/7");
  //$('.steps-indicator__hint').html('Bienvenue chez Uwandzani !')
  //stepBoxes[current].classList.add("done");
  //stepIndicator.innerHTML = "Etape 1/7";
  //stepHint.innerHTML = "";
  //current -= 1;
});

/*
nextBtnFourth.addEventListener("click", function(event){
    event.preventDefault();
    slidePage.style.marginLeft = "-57.16%";
    stepBoxes[current].classList.add("done");
    stepIndicator.innerHTML = "Etape 5/7";
    stepHint.innerHTML = "";
    current += 1;
});
*/

$(".next-4").click(function (event) {
  steep_5(event);
});

$(".retour6").click(function (event) {
  event.preventDefault();
  slidePage.style.marginLeft = "-71.45%";
  $("#e7").removeClass("done");
  $(".steps-indicator__index").html("");
  $(".steps-indicator__index").html("Etape 6/7");
  //$('.steps-indicator__hint').html('Bienvenue chez Uwandzani !')
  //stepBoxes[current].classList.add("done");
  //stepIndicator.innerHTML = "Etape 1/7";
  //stepHint.innerHTML = "";
  //current -= 1;
});
/*
nextBtnFifth.addEventListener("click", function(event){
    event.preventDefault();
    slidePage.style.marginLeft = "-71.45%";
    $(".inscription-page__page--6").removeClass("inscription-page__page--overflow");
    stepBoxes[current].classList.add("done");
    stepIndicator.innerHTML = "Etape 6/7";
    stepHint.innerHTML = "Vous y êtes presque ";
    current += 1;
});
*/

$(".next-5").click(function (event) {
  event.preventDefault();
  steep_6();
});

$("#valider-inscription").click(function (event) {
  event.preventDefault();
  username = document.getElementById("registration_profil_user_username");
  password1 = document.getElementById(
    "registration_profil_user_password_first"
  );
  password2 = document.getElementById(
    "registration_profil_user_password_second"
  );

  if (username != null && username.value == "") {
    $("#erreur_steep7").text("Veuillez compléter tous les champs");
    username.classList.add("manque");
  } else if (
    !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      username.value
    )
  ) {
    $("#erreur_steep7").text("Adresse électronique invalide");
  } else if (password1 != null && password1.value == "") {
    username.classList.remove("manque");
    $("#erreur_steep7").text("Veuillez compléter tous les champs ");
    password1.classList.add("manque");
  } else if (password2 != null && password2.value == "") {
    password1.classList.remove("manque");
    $("#erreur_steep7").text("Veuillez compléter tous les champs  ");
    password2.classList.add("manque");
  } else if (
    password2 != null &&
    password1 != null &&
    password2.value != password1.value
  ) {
    $("#erreur_steep7").text("Les deux mots de passe ne sont pas identiques. ");
    password2.classList.add("manque");
    password1.classList.add("manque");
  } else {
    $(".inscription-page__form").submit();
  }
});

$("#btn-valider-update-profil").click(function (event) {
  event.preventDefault();

  $("#erreur_steep7").text("");
  $(".inscription-page__form").submit();
});

$(".inscription-page__img").on("load", function () {
  $(this).addClass("after-img-change");
});

$("#btn-valider-update-identity").click(function (event) {
  event.preventDefault();
  propos = document.querySelector(".bio");
  if (caractereApropo() < 150) {
    $("#erreur_steep7").text(
      "Votre description doit comporter au minimum 150 caractères"
    );
    propos.classList.add("manque");
  } else if (caractereApropo() > 700) {
    $("#erreur_steep7").text(
      "Votre description doit comporter au maximum 600 caractères"
    );
    propos.classList.add("manque");
  } else {
    propos.classList.remove("manque");

    $("#erreur_steep7").text("");
    $("#form-identity").submit();
  }
});

$(".inscription-page__img").on("load", function () {
  $(this).addClass("after-img-change");
});
/*
nextBtnSixth.addEventListener("click", function(event){
    event.preventDefault();
    slidePage.style.marginLeft = "-85.74%";
    $(".inscription-page__page--6").addClass("inscription-page__page--overflow");
    stepBoxes[current].classList.add("done");
    stepIndicator.innerHTML = "Etape 7/7";
    stepHint.innerHTML = "Ça y est !";
    current += 1;
});
*/

// prevBtnSec.addEventListener("click", function(event){
//   event.preventDefault();
//   slidePage.style.marginLeft = "0%";
//   bullet[current - 2].classList.remove("active");
//   progressCheck[current - 2].classList.remove("active");
//   progressText[current - 2].classList.remove("active");
//   current -= 1;
// });
// prevBtnThird.addEventListener("click", function(event){
//   event.preventDefault();
//   slidePage.style.marginLeft = "-25%";
//   bullet[current - 2].classList.remove("active");
//   progressCheck[current - 2].classList.remove("active");
//   progressText[current - 2].classList.remove("active");
//   current -= 1;
// });
// prevBtnFourth.addEventListener("click", function(event){
//   event.preventDefault();
//   slidePage.style.marginLeft = "-50%";
//   bullet[current - 2].classList.remove("active");
//   progressCheck[current - 2].classList.remove("active");
//   progressText[current - 2].classList.remove("active");
//   current -= 1;
// });

// Avatar upload
$("#profile-container").click(function (e) {
  $("#inscription-image-upload").click();
});

$("#inscription-image-upload").change(function () {
  fasterPreview(this);
});

$(".custom-form__password-toogler").click(function () {
  $(this).toggleClass("custom-form__password-toogler--visible");
  $input = $(this).parent().find("input");

  if ($input.attr("type") == "password") {
    $input.attr("type", "text");
  } else {
    $input.attr("type", "password");
  }
});

$(".apropo").keyup(function () {
  caractereApropo();
});

$(".overlay-content a").click(function (el) {
  el.preventDefault;
  document.getElementById("myNav").style.width = "0%";
  $ref = $(this).attr("data-reference");
  $(".overlay-content a").removeClass("active");
  $("#" + $ref).click();
  $(this).addClass("active");
});

$(".overlay-content-profil a").click(function (el) {
  document.getElementById("myNav").style.width = "0%";
});

if ($($(".ayantEnfant")).val() != "Oui") {
  $(".input-age-enfant").parent("div").hide();
}

$(".ayantEnfant").change(function () {
  if ($(this).val() != "Oui") {
    $(".input-age-enfant").parent("div").hide();
  } else {
    $(".input-age-enfant").parent("div").show();
  }
});

$("#ajouter-facturation").change(function () {
  if ($(this).is(":checked")) {
    $("#form-carte").text("2");
    $("#form-facture").show();
  } else {
    $("#form-carte").text("1");
    $("#form-facture").hide();
  }
});
$(function () {
  caractereApropo();
});

function caractereApropo() {
  var el = document.getElementsByClassName("apropo");
  if (el != undefined) {
    var valeur = document.getElementsByClassName("apropo")[0]?.value ?? "";
    var taille = valeur.length;
    var message = taille + "  caractère(s)";
    if (document.getElementById("mots-saisie") != undefined) {
      document.getElementById("mots-saisie").innerHTML = message;
    }
    return taille;
  }
}

//fonction steep profil
function steep_1(event) {
  let valeurRetour = false;
  informations = document.querySelectorAll(
    ".inscriptionFormation .js-example-basic-single"
  );
  if (informations.length == 0) {
    $("#erreur_steep1").text("Veuillez choisir une langue");
  } else {
    valeur = false;
    informations.forEach((element) => {
      if (element.value != "") {
        valeur = true;
        return 0;
      }
    });
    if (valeur) {
      valeurRetour = true;

      event.preventDefault();
      slidePage.style.marginLeft = "-14.29%";
      //stepBoxes[current].classList.add("done");
      $("#e2").addClass("done");
      $(".steps-indicator__hint").html("");
      $("#msg_info_update").html("");
      $(".steps-indicator__index").html("");
      $(".steps-indicator__index").html("Etape 2/7");

      $("#erreur_steep1").text("");
    } else {
      $("#erreur_steep1").text("Veuillez choisir une langue");
    }
  }

  return valeurRetour;
}

function steep_2(event) {
  let valeurRetour = false;
  langues = document.querySelectorAll(".languesParle_content");

  if (langues.length == 0) {
    $("#erreur_steep2").text("Veuillez choisir une langue");
  } else {
    valeur = true;
    langues.forEach((element) => {
      Elmentselect = element.getElementsByTagName("select")[1];
      if (Elmentselect.value == "") {
        Elmentselect.classList.add("manque");
        valeur = false;
        return 0;
      }
    });
    if (valeur) {
      valeurRetour = true;

      event.preventDefault();

      slidePage.style.marginLeft = "-28.58%";

      $("#e3").addClass("done");
      $(".steps-indicator__index").html("");
      $(".steps-indicator__index").html("Etape 3/7");

      $("#erreur_steep2").text("");
    } else {
      if (langues.length == 1) {
        $("#erreur_steep2").text(
          "Veuillez chosir votre niveau de maitrise de la langue "
        );
      } else {
        $("#erreur_steep2").text(
          "Veuillez chosir votre niveau de maitrise pour chaque langue"
        );
      }
    }
  }

  return valeurRetour;
}

function steep_3(event) {
  valeurRetour = false;
  chackboxs = document.querySelectorAll(".list-hobby .form-check-input");
  valeur = false;
  chackboxs.forEach((element) => {
    if (element.checked) {
      valeur = true;
      return 0;
    }
  });
  if (valeur) {
    valeurRetour = true;

    event.preventDefault();

    slidePage.style.marginLeft = "-42.87%";

    $("#e4").addClass("done");
    $(".steps-indicator__index").html("");
    $(".steps-indicator__index").html("Etape 4/7");

    $("#erreur_steep3").text("");
  } else {
    $("#erreur_steep3").text("Veuillez choisir au moins un hobby");
  }

  return valeurRetour;
}

function steep_4(event) {
  let valeurRetour = false;

  min = document.querySelector(".input-minAge");
  max = document.querySelector(".input-maxAge");

  chackboxs = document.querySelectorAll(".list-hobby-ami .form-check-input");
  valeur = false;
  chackboxs.forEach((element) => {
    if (element.checked) {
      valeur = true;
      return 0;
    }
  });

  if (min.value == "" || max.value == "") {
    $("#erreur_steep4").text("Veuillez saisir l'âge minimum et maximum");
    if (max.value == "") {
      max.classList.add("manque");
    } else {
      max.classList.remove("manque");
    }
    if (min.value == "") {
      min.classList.add("manque");
    } else {
      min.classList.remove("manque");
    }
  } else if (parseInt(min.value) > parseInt(max.value)) {
    $("#erreur_steep4").text(
      "L'âge maximum doit supérieur ou égal à l'âge minimum"
    );
    max.classList.add("manque");
    min.classList.add("manque");
  } else if (!(parseInt(min.value) <= 120 && parseInt(min.value) >= 18)) {
    $("#erreur_steep4").text("L'âge maximum doit être entre 18 à 120");
    min.classList.add("manque");
  } else if (!(parseInt(max.value) <= 120 && parseInt(max.value) >= 18)) {
    min.classList.remove("manque");
    $("#erreur_steep4").text("L'âge maximum doit être entre 18 à 120");
    max.classList.add("manque");
  } else if (valeur) {
    valeurRetour = true;

    event.preventDefault();

    slidePage.style.marginLeft = "-57.16%";

    $("#e5").addClass("done");
    $(".steps-indicator__index").html("");
    $(".steps-indicator__index").html("Etape 5/7");
    //stepBoxes[current].classList.add("done");
    //stepIndicator.innerHTML = "Etape 5/7";
    //stepHint.innerHTML = "";
    //current += 1;

    min.classList.remove("manque");
    max.classList.remove("manque");
    $("#erreur_steep4").text("");
  } else {
    min.classList.remove("manque");
    max.classList.remove("manque");
    $("#erreur_steep4").text("Veuillez choisir au moins un hobby");
  }
  return valeurRetour;
}

function steep_5(event) {
  let valeurRetour = false;

  chackboxs = document.querySelectorAll(".list-interesser .form-check-input");
  valeur = false;
  chackboxs.forEach((element) => {
    if (element.checked) {
      valeur = true;
      return 0;
    }
  });
  if (valeur) {
    valeurRetour = true;

    event.preventDefault();
    slidePage.style.marginLeft = "-71.45%";
    $(".inscription-page__page--6").removeClass(
      "inscription-page__page--overflow"
    );
    $("#e6").addClass("done");
    $(".steps-indicator__index").html("");
    $(".steps-indicator__index").html("Etape 6/7");

    $("#erreur_steep5").text("");
  } else {
    $("#erreur_steep5").text("Veuillez choisir au moins un type de relation");
  }

  return valeurRetour;
}

function steep_6() {
  let valeurRetour = false;
  pseudo = document.querySelector(".pseudo");
  sexe = document.querySelector(".sexe");
  propos = document.querySelector(".bio");
  date = document.getElementById("registration_profil_birthAt");

  if (pseudo.value == "") {
    $("#erreur_steep6").text(
      "Veuillez compléter tous les champs obligatoires "
    );
    pseudo.classList.add("manque");
  } else if (sexe.value == "") {
    pseudo.classList.remove("manque");
    $("#erreur_steep6").text(
      "Veuillez compléter tous les champs obligatoires "
    );
    sexe.classList.add("manque");
  } else if (date != undefined && date.value == "") {
    $("#erreur_steep6").text(
      "Veuillez compléter tous les champs obligatoires "
    );
    date.classList.add("manque");
    sexe.classList.remove("manque");
  } else if (caractereApropo() < 150) {
    if (date != undefined) {
      date.classList.remove("manque");
    } else {
      sexe.classList.remove("manque");
    }

    $("#erreur_steep6").text(
      "Votre description doit comporter au minimum 150 caractères"
    );
    propos.classList.add("manque");
  } else if (caractereApropo() > 700) {
    sexe.classList.remove("manque");
    $("#erreur_steep6").text(
      "Votre description doit comporter au maximum 600 caractères"
    );
    propos.classList.add("manque");
  } else {
    valeurRetour = true;

    event.preventDefault();
    slidePage.style.marginLeft = "-85.74%";
    $(".inscription-page__page--6").addClass(
      "inscription-page__page--overflow"
    );
    $("#e7").addClass("done");
    $(".steps-indicator__index").html("");
    $(".steps-indicator__index").html("Etape 7/7");
    //stepBoxes[current].classList.add("done");
    //stepIndicator.innerHTML = "Etape 7/7";
    //stepHint.innerHTML = "Ça y est !";
    //current += 1;

    $("#erreur_steep6").text("");
    pseudo.classList.remove("manque");
    sexe.classList.remove("manque");
    propos.classList.remove("manque");
  }
  return valeurRetour;
}

function steep_7(event) {
  $("#erreur_steep7").text("");

  return true;
}

function openTabError(evt, tabName) {
  var i, tabcontent, tablinks;

  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }

  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }

  document.getElementById(tabName).style.display = "block";
  evt.className += " active";
}

function fasterPreview(uploader) {
  if (uploader.files && uploader.files[0]) {
    $profileImage = $("#profile-image");
    $profileImage.removeClass("inscription-page__img--initial");

    $profileImage.attr("src", window.URL.createObjectURL(uploader.files[0]));
  }
}

function validationImage(element) {
  var fileInput = document.getElementById(element);
  var filePath = fileInput.value;
  var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  return allowedExtensions.exec(filePath);
}
