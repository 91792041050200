delete_content()


var index = $('.formationDonnerAide').length + 1;
$(function () {
	$('#btn-formationDonnerAide-add').click(function(e){
		e.preventDefault();
		delete_content();
		//$('.formationRecevoirAide .row').remove()
	});
});


var indexReception = $('.formationRecevoirAide').length + 1;
$(function () {
    $('#btn-formationRecevoirAide-add').click(function(e){
        e.preventDefault();

        delete_content();
	
    });
});



function delete_content() {
	$('.delete_content').click(function(e) {
		e.preventDefault();
		$(this).parent().remove();
	})
}